@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins;
    overflow-x: hidden;
    /* border: 1px solid red; */
}

div{
  overflow-y: hidden;
}

h1, h2, h3, h4, h5, h6, p, button{
    font-family: Poppins; 
}

.main-logo{
  width: 48px;
  height: auto;
}

.main-hero{
padding: 30px 0px;
}

.hero-content{
  padding-left: 55px;
  padding-top: 20px;
}

.hero-content h1{
    font-size: clamp(2.3125rem, 1.9425rem + 1.5062vw, 3.75rem);   
     font-style: normal;
font-weight: 700;
text-transform: uppercase;
overflow-y: hidden;
line-height: clamp(2.9375rem, 2.5032rem + 1.7682vw, 4.625rem);
}

.hero-img{
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
    background-image: url('../images/hero.png'); /* Replace with your image URL */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent repeating the image */
    object-fit: cover; /* Ensure the image covers the container */
}


.services-heading{
  
    color: #000;
font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: clamp(0.85rem, 0.7132rem + 0.5567vw, 1.38125rem);
line-height: clamp(1.0625rem, 0.25585rem + 3.28423vw, 4.196875rem);
}

.services-heading span{
    color: #BCB5B5;
}

.form-section {
    display: flex;
    align-items: center;
}

.form-heading {
    margin-top: 40px;
    display: flex;
    align-items: center;
    font-size: clamp(1.25rem, 1.057rem + 0.7859vw, 2rem);
    overflow-y: hidden;
}

.form-border {
    display: inline-block;
    margin-top: 15px;
    overflow: hidden;
    border-bottom:  clamp(0.25rem, 0.2017rem + 0.1965vw, 0.4375rem) solid #000;
    width: clamp(7.5rem, 4.6046rem + 11.7878vw, 18.75rem); /* Adjust the width as needed */
    margin-left: 10px; /* Adjust the spacing as needed */
}

.main-form{
    margin-top: 15px;
}

.main-form .form-control {
  color: #000;
  font-weight: 500;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #B8B7B7;
  padding-left: 0 ; /* Remove left padding */
}

.main-form label {
  color: #959090;
  padding-left: 0; /* Remove left padding for placeholder text */
}

/* Placeholder text */
.main-form .form-control::placeholder {
  padding-left: 0; /* Remove left padding for placeholder text */
}

/* Floating labels, if you are using them */
.main-form .form-control-label {
  padding-left: 0; /* Remove left padding for floating labels */
  margin-left: 0; /* Remove any left margin for floating labels */
}

/* Input text */
.main-form .form-control input {
  padding-left: 0; /* Remove left padding for input text */
  margin-left: 0; /* Ensure no left margin */
}

.main-form  .form-control:focus{
box-shadow: none;
}



.main-form  label{
    color: #000;
  font-weight: 500;
}


/* .main-form .form-control label:focus{
    color: #000;
} */

.submit-button .btn-primary{
    margin-top: 20px;
    background: #242121;
    border: 0px;
    box-shadow: 0px;
    padding-right: 33px;
    padding-left: 20px;
    overflow-y: hidden;
}

.submit-button .btn-primary:hover{
    background: #0E89FB;

}

.submit-button .btn-primary:focus{
    background: #0E89FB;
    border: 0px;
    box-shadow: none !important;
}

.submit-button i{
  overflow-y: hidden !important;
}

.whatsapp-icon{
  text-decoration: none;
  overflow-y: hidden;
  overflow: hidden;
}

.whatsapp-icon i{
  text-decoration: none;
  overflow-y: hidden;
  overflow: hidden;
}



.our-services{
  position: relative;
  z-index: 99999999;
    padding: 125px 0px;
    background-color: #000;
    

}

.our-services h2{
    color: #FFF;
    font-size: clamp(2.25rem, 1.0918rem + 4.7151vw, 6.75rem);
font-style: normal;
font-weight: 700;
margin-bottom: 60px;
overflow-y: hidden;
}

.services-main {
    border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    min-height: 140px; /* Minimum height */
    padding: 20px 10px;
    overflow-y: hidden; /* Hide overflow */
    display: table; /* Use table display for consistent layout */
    width: 100%;
}

.services-main .row {
    display: table-row; /* Each row behaves like a table row */
}

.services-main .row > div {
    display: table-cell; /* Each column behaves like a table cell */
    vertical-align: middle; /* Align content vertically */
}

.services-mainmobile{
    border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    min-height: 140px;
    padding: 20px 10px;
    overflow-y: hidden; /* Hide overflow */
    width: 100%;
    margin: 20px 0px;
}


.services-boxed:first-of-type {
    border-top: 1px solid rgba(255, 255, 255, 0.40);
}


.services-boxed svg{
   
    margin-bottom: 10px;
}

.services-boxed h4{
    color: #FFF;
    overflow-y: hidden;
    font-size: clamp(1rem, 0.5496rem + 1.8337vw, 2.75rem);
    font-weight: 600;
    margin-bottom: 10px;
}

.services-boxed p{
    color: #FFF;
    font-size: clamp(0.80rem, 0.7228rem + 0.31432vw, 1.1rem);
    font-weight: 300;
}

.about-section{
    padding: 125px 0px;
}

.about-box{
  margin-top: 10px;
padding-right: 22px;
overflow-y: hidden;
}

.about-box h3{
    color: #282828;
    font-size: clamp(1.5rem, 1.1139rem + 1.5717vw, 3rem);
    font-weight: 700;
    overflow-y: hidden;
    }

    .about-box h6{
    color: #282828;
    font-size: clamp(0.875rem, 0.7463rem + 0.5239vw, 1.375rem);
font-weight: 500;
padding-bottom: 15px;
overflow-y: hidden;
    }


    .about-box p{
        color: #282828;
        font-size: clamp(0.875rem, 0.7624rem + 0.4584vw, 1.3125rem);
    font-weight: 500;
    overflow-y: hidden;
        }
    

.cta-banner{
    padding: 180px 0px;
    background-image: url('../images/ctaimg.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.cta-banner h4{
    color: #FFF;
    overflow-y: hidden;
    font-family: Poppins;
    font-size: clamp(1.375rem, 0.7638rem + 2.4885vw, 3.75rem);
    font-weight: 300;
    line-height:clamp(1.625rem, 0.8207rem + 3.2744vw, 4.75rem);
}

.about-section h2{
    color: #282828;
    font-size: clamp(2.375rem, 1.249rem + 4.5842vw, 6.75rem);
    font-weight: 700;
    overflow-y: hidden;
}

.about-section h5{
    color: #282828;
    font-size: clamp(1rem, 0.8713rem + 0.5239vw, 1.5rem);
    font-weight: 600;
    line-height:  clamp(1.375rem, 1.1659rem + 0.8513vw, 2.1875rem);
    overflow-y: hidden;
}

.about-section p{
    color: #282828;
    font-size: clamp(1rem, 0.8713rem + 0.5239vw, 1.5rem);
    font-weight: 300;
    line-height:  clamp(1.375rem, 1.1659rem + 0.8513vw, 2.1875rem);
    overflow-y: hidden;
}


.testimonials-sec{
    background-color: #fff;
    padding: 100px 0px;
}

.testimonials-sec .heading-sec h2{
    color: #133240;
    font-size: clamp(1.5rem, 1.2105rem + 1.1788vw, 2.625rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.856px;
}

.testimonials-sec .heading-sec p{
    color: #133240;
    font-size: clamp(1rem, 0.7909rem + 0.8513vw, 1.8125rem);
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.588px;
}
  


  .slick-slide {
    padding: 0 10px; /* Add margin between the slides */
  }
  
  .testimonial-card {
    background: #fff;
    border: 1px solid #ddd;
    padding: 25px;
    /* margin: 10px; */
  }
  
  .testimonial-content {
    color: #212121;
    font-size: clamp(0.85rem, 0.741605rem + 0.445315vw, 1.275rem);
    font-style: normal;
    font-weight: 300;
    line-height: 164.5%;
    height: 250px; /* Set your fixed height here */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  
  
  .user-info {
    display: flex;
    align-items: center;
    margin-top: 25px !important;
  }
  
  .user-info img {
    width: 50px;
    height: 50px;
    border: 0.5px solid #d3d3d3;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-info .name {
    color: #000;
    font-size: clamp(1rem, 0.9196rem + 0.3274vw, 1.3125rem);
    font-style: normal;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  
  .user-info .designation {
    color: #4F4F4F;
    font-size: clamp(1rem, 0.9678rem + 0.131vw, 1.125rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  

  .custom-navigation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px !important;
  }
  
  .prev-btn, .next-btn {
    width: 40px; /* Adjust icon size */
    height: 40px; /* Adjust icon size */
    cursor: pointer;
  }
  
  .nav-gap {
    width: 20px; /* Adjust the gap width */
  }


.footer-info{
    background: #000;
    padding: 30px 0px;
}

.footer-info .text-right{
    text-align: end;
    justify-content: end;
}

.footer-info p{
    color: #FFF;
    font-family: Poppins;
    font-size: clamp(1rem, 0.8713rem + 0.5239vw, 1.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}

.footer-info .fa-phone-volume{
    margin-right: 20px;
}



.social-icons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px; /* Adjust the spacing between icons as needed */
  }
  
  .icon {
    font-size: clamp(1rem, 0.8713rem + 0.5239vw, 1.5rem);
    color: #ffffff; /* Adjust the color of the icons as needed */
    transition: color 0.3s;
    overflow-y: hidden !important;
  }
  
  .icon:hover {
    color: #0E89FB; /* Change to desired hover color */
    cursor: pointer;
  }


  /* Media Screens */


  @media (max-width: 1600px) {

    .hero-content h1{
        font-size: 48px;   
    }

    .about-box p{
      font-size: 18.5px;
      }

      .about-section p{
        font-size: 18.5px;
      }
  

      .testimonial-content{
        height: 250px;
      }

  }

  @media (max-width: 1400px) {
    .hero-content h1{
        font-size: 44px;   
    }

    .testimonial-content{
      height: 240px;
    }


    .about-box p{
      font-size: 18px;
      }

      .about-box p{
        font-size: 18px;
        }
  
        .about-section p{
          font-size: 18px;
        }
  

  }

  @media (max-width: 1280px) {
    .hero-content h1{
      font-size: 42px;   
  }

  .testimonial-content{
    height:auto !important;
  }
  }

  @media (max-width: 1024px) {
    .hero-content h1{
        font-size: 40px;   
    }

    .about-section h2{
      font-size: 50px;
    }

    .hero-content{
      padding-left: 20px;
    }

    .testimonial-content{
      height: 240px;
    }
  }

  @media (max-width: 768px) {

    .hero-content{
        padding-left: 0px;
      }

      .testimonials-sec{
        background-color: #fff;
        padding: 50px 0px;
    }
    
    .testimonial-content{
      height:165px;
    }

    .footer-info .text-right{
        text-align: start;
        margin-top: 30px;
    }
    .hero-content h1{
        font-size: 37px;   
    }

    .about-box p{
      font-size: 16px;
      }

      .about-section{
        padding: 90px 0px;
    }

      .about-section p{
        font-size: 16px;
      }


    .testimonial-card {
        padding: 15px;
        margin: 0px;
      }

      .about-box{
         margin-top: 60px;
        }

.social-icons {
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 20px; /* Adjust the spacing between icons as needed */
  }

  .cta-banner{
    padding: 75px 0px 280px 0px;
    background-image: url('../images/ctamobile.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.slick-slide {
    padding: 0 0px; /* Add margin between the slides */
  }

  .about-box{
      padding-right: 20px;
  }

  }

@media (max-width: 480px) {

    .hero-content h1{
        font-size: 28px;   
        line-height: 40px;
    }


    .about-section{
      padding: 80px 0px;
  }
   
    .services-heading{
    font-size: 15px;
    line-height: 22px;
    }

    .testimonial-content{
      height:180px !important;
    }

  }

  @media (min-width: 1840px) {


.our-services{
  padding: 140px 0px;
}


.about-section{
  padding: 140px 0px;
}

.cta-banner{
  padding: 260px 0px;

}

  
.testimonial-card {
  padding: 50px !important;
}

.testimonial-content{
  height: 268px;
}

.user-info {

  margin-top: 55px !important;
}

.about-box{
  margin-top: 40px;
}


}



/* Full Screen Conatiner */


  .container {
    width: 100%;
    max-width: 1400px; /* Example maximum width for desktop */
    margin: 0 auto; /* Center align the container */
    padding: 0 20px; /* Example padding to add space on the sides */
  }
  
  /* Media query for desktop screens */
  @media (min-width: 1400px) {
    .container {
      max-width: 1340px; /* Adjust maximum width for larger screens if needed */
    }
  }

    /* Media query for larger screens */
    @media (min-width: 1500px) {
      .container {
        max-width: 1420px; /* Example: Increase maximum width for very large screens */
      }
    }

  
  /* Media query for larger screens */
  @media (min-width: 1600px) {
    .container {
      max-width: 1500px; /* Example: Increase maximum width for very large screens */
    }
  }
  
  @media (min-width: 1800px) {
    .container {
      max-width: 1680px; /* Example: Increase maximum width for very large screens */
    }
  }
  
  @media (min-width: 1920px) {
    .container {
      max-width: 1720px; /* Example: Increase maximum width for very large screens */
    }
  }


  
/* Desktop & Mobile Hide Show */


  .desktop-hide{
    display: none;
  }


  .mobile-hide{
    display: flex;
  }

  @media (max-width: 768px) {
    .desktop-hide{
        display: flex;
      }
    
    
      .mobile-hide{
        display: none;
      }
  }



/* animations.css */

/* Desktop Animation */
@keyframes slideUpDesktop {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}




/* Mobile Animation */
@keyframes slideUpMobile {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.services-main,
.services-mainmobile {
  opacity: 0; /* Initial state: hidden */
  transition: opacity 1s ease-out;
}

.services-main.animate-desktop,
.services-mainmobile.animate-mobile {
  animation: slideUpMobile 1s forwards;
}




/* Slide Up Animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 1.5s forwards;
}

/* animations.css */
/* animations.css */

/* Slide Right Animation */
@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(28px);
  }
}

/* Slide Left Animation */
@keyframes slideLeft {
  from {
    transform: translateX(28px);
  }
  to {
    transform: translateX(0);
  }
}

.btn-primary .fa-arrow-right-long {
  display: inline-block;
  transition: transform 0.5s ease;
}

.btn-primary:hover .fa-arrow-right-long {
  animation: slideRight 0.5s forwards;
}

.btn-primary:not(:hover) .fa-arrow-right-long {
  animation: slideLeft 0.5s forwards;
}

.btn-primary {
  display: flex;
  align-items: center; /* Vertically center items */
}

.btn-primary .fa-arrow-right-long {
  margin-right: 8px; /* Adjust margin as needed */
}

/* Add this to your CSS stylesheet */
.testimonial-card.animate-slide-up {
  animation: slideUp 1.5s forwards;
}

.animate-slide-up {
  animation: slideUp 1.5s forwards;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.services-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2; /* Ensure services appear above home screen */
}

.home-screen {
  position: relative; /* Ensure it's not overlapping with services */
  z-index: 1; /* Keep home screen behind services */
}



.services-selection{
 border-bottom: 1px solid #b8b7b7;
 margin-top: 30px;
}


.checkbox-text{
  color: #2b2b2b !important;
  font-weight: 400 !important;
  font-size: 15px;
  }

  .form-check-input:checked{
    box-shadow: none;
    background-color: #000;
    border: 1px solid black;
  }

  .form-check-input:focus{
    box-shadow: none;

  }

  .call-button {
    position: fixed;
    bottom: 30px;  /* 40px from the bottom */
    right: 10px;   /* 30px from the right */
    background-color: #28a745; /* Green background */
    color: white;  /* White icon color */
    width: 50px;   /* Width of the button */
    height: 50px;  /* Height of the button */
    border-radius: 50%; /* Round shape */
    display: flex; /* Use flexbox to center the icon */
    align-items: center; /* Vertically center the icon */
    justify-content: center; /* Horizontally center the icon */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);  /* Shadow effect */
    text-decoration: none; /* Remove underline from link */
    z-index: 789029192 !important;

}

.call-button a {
    color: white;  /* Make the icon color white */
    font-size: 20px; /* Icon size */
    margin-top: 5px;
    overflow-y: hidden !important;
    overflow: hidden;

}

.fa-solid{
  overflow-y: hidden !important;
  overflow: hidden;
}

.testimonial-content::-webkit-scrollbar {
  width: 4px !important; /* Width of the scrollbar */
  height: 30px !important; /* Height of the horizontal scrollbar (if needed) */
}

.testimonial-content::-webkit-scrollbar-thumb {
  background-color: #000000 !important; /* Color of the scrollbar thumb */
  border-radius: 10px !important; /* Rounded corners for the thumb */
  border-radius: 10px;
}

.testimonial-content::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important; /* Color on hover */
}

.accordion-item{
  border: none;
}

.accordion-button:not(.collapsed){
  color: #000;
  font-weight: 500;
  padding-left: 0px;
  border: 5px;
  background-color: transparent;
  box-shadow: none;
}

.accordion-body{
  padding: 8px 8px;
}

.accordion-button:not(.collapsed)::after{
  /* background-image: none; */
  transform: rotate(0deg);
  color: #000;
  margin-right: -7.5px;
  background-image: url(../images/down-arrow.png);
}

.accordion-button::after{
  background-size: 1rem;
  height: 1rem;
  width: 1rem;
    background-image: url(../images/down-arrow.png);
}









